import React from "react";

import Layout from "../../layouts/Layout";
import VerticalBanner from "../../partials/VerticalBanner";
import VerticalCategoriesSection from "../../partials/VerticalCategoriesSection";
import VerticalCaseStudySection from "../../partials/VerticalCaseStudySection";
import VerticalInfoBanner from "../../partials/VerticalInfoBanner";
import PlatformSection from "../../partials/PlatformSection";
import VerticalPartnersSection from "../../partials/VerticalPartnersSection";
import VerticalNetworkSection from "../../partials/VerticalNetworkSection";

import { useMediaQuery } from "../../utils/hooks";

const ShoppingCentresMallsPage = props => {
  const {
    pageContext: { caseStudy },
  } = props;

  const isMobile = useMediaQuery("(max-width: 575px)");

  return (
    <Layout
      pageTitle="Brandcrush for Shopping centre operators"
      ogTitle="Brandcrush for Shopping centres and malls| Transform your popup & advertising sales with an end-to-end software solution."
      ogImage="/images/shopping@3x.png"
      ogDescription="Transform your popup & advertising sales with an end-to-end software solution."
    >
      <VerticalBanner
        vertical="Shopping centres"
        heading="Unlock your retail media potential"
        subHeading="Maximize your revenue and transform high-touch media & short term lease bookings, all in one powerfully simple platform."
        backgroundImgSrc="/images/cloud-vertical-navy.svg"
        imageSrc="/images/shopping@3x.png"
      />
      <VerticalCategoriesSection
        heading="Transform your popup & advertising sales with an end-to-end software solution."
        subHeading="Make it easy for your clients to find and book activations and media with your properties by taking it all online."
        categories={[
          {
            label: "In-centre",
            iconSrc: "/images/onsite.svg",
            activationTypes: [
              "Short term leases",
              "Pop-ups",
              "Posters & decals",
              "Roaming",
              "Travelator Advertising",
              "Digital screens",
              "Product placement",
              "Car park pop-ups",
            ],
            color: "light-blue",
          },
          {
            label: "Online",
            iconSrc: "/images/online.svg",
            activationTypes: [
              "Website promotion",
              "Digital sponsorship",
              "Social sponsorship",
              "Email sponsorship",
              "Digital catalogues",
            ],
            color: "topaz",
          },
        ]}
      />
      <VerticalCaseStudySection caseStudy={caseStudy} />
      <PlatformSection
        topHeading="Maximize your media sales"
        topCopy="Grow and scale revenue with custom platform tools."
        topGraphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/sales-graph@3x.png",
            x: isMobile ? 1.406 : 3.125,
            y: isMobile ? 4.404 : 9.688,
            delay: 0.2,
            width: isMobile ? 15.938 : 35.063,
          },
          {
            image: "/images/sales-stats@3x.png",
            x: isMobile ? 1.406 : 3.125,
            y: isMobile ? 1.847 : 4.063,
            delay: 0.4,
            width: isMobile ? 15.938 : 35.063,
          },
        ]}
        topButtonLabel="Schedule a demo"
        topButtonHref="/book-a-demo"
        middleHeading="Streamline your bookings"
        middleCopy="Manage and scale your bookings with inventory tools, booking calendars and campaign workflows."
        middleGraphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/activations-logo-shopping@3x.png",
            x: isMobile ? 1.42 : 3.125,
            y: isMobile ? 10.417 : 22.917,
            delay: 0.2,
            height: isMobile ? 2.273 : 5.0,
          },
          {
            image: "/images/activations-name@3x.png",
            x: isMobile ? 4.375 : 9.625,
            y: isMobile ? 11.364 : 25.0,
            delay: 0.2,
            height: isMobile ? 0.369 : 0.8125,
          },
          {
            image: "/images/activations-table@3x.png",
            x: isMobile ? 1.932 : 4.25,
            y: isMobile ? 2.178 : 4.792,
            delay: 0.4,
            height: isMobile ? 7.188 : 15.8125,
          },
        ]}
        middleButtonLabel="Schedule a demo"
        middleButtonHref="/book-a-demo"
        bottomHeading="Simplify your payments"
        bottomCopy="Let us handle full vendor management, payments and collections, or integrate with your existing finance systems."
        bottomGraphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/payments-summary@3x.png",
            x: isMobile ? 1.136 : 2.5,
            y: isMobile ? 4.318 : 9.5,
            delay: 0.2,
            width: isMobile ? 7.67 : 16.875,
          },
          {
            image: "/images/payments-table@3x.png",
            x: isMobile ? 9.943 : 21.875,
            y: isMobile ? 2.159 : 4.75,
            delay: 0.4,
            width: isMobile ? 7.67 : 16.875,
          },
          {
            image: "/images/payments-button@3x.png",
            x: isMobile ? 1.932 : 4.25,
            y: isMobile ? 2.159 : 4.75,
            delay: 0.6,
            width: isMobile ? 6.051 : 13.313,
          },
        ]}
        bottomButtonLabel="Schedule a demo"
        bottomButtonHref="/book-a-demo"
        lastHeading="Integrate systems"
        lastCopy="Integrate with finance, CRM and partner platforms"
        lastGraphics={[
          {
            image: "/images/window@3x.png",
            x: 0,
            y: 0,
            delay: 0.0,
            height: isMobile ? 14.75 : 32.5,
          },
          {
            image: "/images/integrate@3x.png",
            x: isMobile ? 6.279 : 13.813,
            y: isMobile ? 4.261 : 9.375,
            delay: 0.1,
            width: isMobile ? 6.506 : 14.313,
          },
          {
            image: "/images/integrate-cog-1@3x.png",
            x: isMobile ? 4.689 : 10.25,
            y: isMobile ? 10.739 : 23.625,
            delay: 0.3,
            width: isMobile ? 1.193 : 2.625,
          },
          {
            image: "/images/integrate-cog-1@3x.png",
            x: isMobile ? 12.955 : 28.5,
            y: isMobile ? 2.784 : 6.125,
            delay: 0.4,
            width: isMobile ? 1.193 : 2.625,
          },
          {
            image: "/images/integrate-cog-2@3x.png",
            x: isMobile ? 13.551 : 29.813,
            y: isMobile ? 7.045 : 15.5,
            delay: 0.5,
            width: isMobile ? 1.534 : 3.375,
          },
          {
            image: "/images/integrate-cog-3@3x.png",
            x: isMobile ? 12.585 : 27.688,
            y: isMobile ? 10.654 : 23.438,
            delay: 0.6,
            width: isMobile ? 1.932 : 4.25,
          },
          {
            image: "/images/integrate-cog-4@3x.png",
            x: isMobile ? 3.55 : 7.813,
            y: isMobile ? 2.216 : 4.875,
            delay: 0.7,
            width: isMobile ? 2.443 : 5.375,
          },
        ]}
        lastButtonLabel="Schedule a demo"
        lastButtonHref="/book-a-demo"
        noActionButtons
        signupHref="/book-a-demo"
      />
      <VerticalInfoBanner
        heading="Your media, your platform"
        subHeading="Drive your media sales with a fully customized and branded media platform, integrated with your website.  - White label media sites, - Custom activation types & categories,  White Label media sites,  - custom campaign works, Multicurrency & multi-location."
        checklistItems={[
          "Media types and categories",
          "Master and child media sites",
          "Campaign workflows",
          "Multi-currency & multi location",
        ]}
      />
      <VerticalPartnersSection heading="Opt in to promote your your popup and advertising opportunities to thousands of new brands via our marketplace" />
      <VerticalNetworkSection
        heading="Join a growing network of shopping centres unlocking and growing their media revenue"
        logos={[
          {
            src: "/images/logos/shopping/QIC_Grey.png",
            alt: "",
            width: isMobile ? "5.47" : "8.75",
          },
          {
            src: "/images/logos/shopping/Fitzroys_Grey.png",
            alt: "",
            width: isMobile ? "2.03" : "3.25",
          },
          { src: "/images/logos/shopping/CHP_Grey.png", alt: "", width: isMobile ? "4.69" : "7.5" },
        ]}
        height="5.875"
        background-color="charcoal-15"
        autoplay={false}
      />
    </Layout>
  );
};

export default ShoppingCentresMallsPage;
